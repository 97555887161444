/* global Liferay */

import { getNextLesson, getPreviousLesson } from "../utils/util";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { getCurrentLanguageKey, getDPTAssetId, getSignInPath, isSignedIn } from "../utils/util";
import { useTranslation } from "react-i18next";
import ClayLoadingIndicator from "@clayui/loading-indicator";
import { loadExerciseQuestions, postExerciseResult } from "../services/exercise";
import { Text } from "@clayui/core";
import { ClayCheckbox, ClayRadio, ClayRadioGroup } from "@clayui/form";
import ClayTabs from "@clayui/tabs";
import ClayButton from "@clayui/button";
import ClayForm, { ClayInput } from "@clayui/form";
import ClayProgressBar from "@clayui/progress-bar";
import QuizResultSheet from "./QuizResultSheet";
import ClayIcon from "@clayui/icon";
import ClayAlert from "@clayui/alert";
import { enrollToCourse, getCourseByQuizId } from "../services/course";

const parseAnswers = (answers) => {
  return JSON.parse(answers);
};

const ExerciseView = forwardRef((props, ref) => {
  const { exerciseId, height, educationPageUrl, badgePageUrl, enableBackButton } = props;

  const { t, i18n: { changeLanguage } } = useTranslation();

  const [active, setActive] = useState(-1);
  const [answers, setAnswers] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const [firstQuestion, setFirstQuestion] = useState(false);
  const [isKnoledgeCheck, setIsKnoledgeCheck] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [moduleInfo, setModuleInfo] = useState({});
  const [nextLesson, setNextLesson] = useState(null);
  const [prevLesson, setPrevLesson] = useState(null);
  const [progress, setProgress] = useState(0);
  const [questions, setQuestions] = useState(null);
  const [scoreSheetOverall, setScoreSheetOverall] = useState(null);
  const [showError, setShowError] = useState(false);
  const { upNextLabel, linkPrefix } = props;
  const [courseId, setCourseId] = useState(null)
  const [isEnrolled, setIsEnrolled] = useState(false)
  const [userEnrollments, setUserEnrollments] = useState(null);

  const currentQuizzId = Liferay.ThemeDisplay.getLayoutURL().split('/').pop();

  useEffect(() => {
    const getCourseId = async () => {

        const courseData = await getCourseByQuizId(currentQuizzId);

        setCourseId(courseData.id);
    };

    getCourseId();
}, [currentQuizzId]);

useEffect(() => {
    const getUserEnrollmentsIds = async () => {

        if (isSignedIn()) {
            const response = await Liferay.Util.fetch(
                `/o/c/enrollments/scopes/${Liferay.ThemeDisplay.getScopeGroupId()}?fields=r_courseEnrollment_c_courseId&filter=r_userenrollments_userId eq '${Liferay.ThemeDisplay.getUserId()}'`
            );
            
            const data = await response.json();

            if (data.items) {
                setUserEnrollments(data.items.map(item => item.r_courseEnrollment_c_courseId));
            }
        }
    };

    getUserEnrollmentsIds();
}, []);

useEffect(() => {

    if (userEnrollments && courseId) {
        setIsEnrolled(userEnrollments?.includes(courseId));
    }
    
}, [userEnrollments, courseId]);

const handleRegisterUser = () => {

    if(!userEnrollments?.includes(courseId)) {
        enrollToCourse(courseId)
    }

    setFirstQuestion(true)
}

  const prepareAnswersObject = (questions) => {
    let answersObject = {};

    questions.forEach((question) => {
      switch (question.type) {
        case "selectSingleChoice": {
          answersObject[question.id] = {};

          answersObject[question.id] = null;

          break;
        }
        case "selectMultipleChoice": {
          answersObject[question.id] = {};

          question.answers.forEach((answer) => {
            answersObject[question.id][answer.id] = false;
          });

          break;
        }
        case "freeText": {
          answersObject[question.id] = "";

          break;
        }
      }
    });

    setAnswers(JSON.stringify(answersObject));
  };

  const validateAnswers = useCallback(() => {
    let valid = true;

    Object.keys(parseAnswers(answers)).map((key, index) => {
      if (!parseAnswers(answers)[key]) {
        valid = false;
      }
    });

    setShowError(!valid);

    return valid;
  }, [answers]);

  const handleClearAnswers = useCallback(() => {
    prepareAnswersObject(questions);
  }, [questions]);

  const validateCurrentQuestion = useCallback(
    (questions) => {
      let currentQuestion = questions[active];

      let isValid = false;

      let currentAnswers = parseAnswers(answers);

      switch (currentQuestion.type) {
        case "selectSingleChoice": {
          isValid =
            currentAnswers[currentQuestion.id] &&
            currentAnswers[currentQuestion.id] != "";
          break;
        }

        case "selectMultipleChoice": {
          if (!currentAnswers[currentQuestion.id]) {
              isValid = false;
          } else {
              const answerKeys = Object.keys(currentAnswers[currentQuestion.id]);

              isValid = answerKeys.some(key => currentAnswers[currentQuestion.id][key]);
          }

          break;
        }      

        case "freeText": {
          isValid =
            currentAnswers[currentQuestion.id] &&
            currentAnswers[currentQuestion.id].length > 0
              ? true
              : false;

          break;
        }
      }

      setShowError(!isValid);

      return isValid;
    },
    [active, answers]
  );

  const handleSubmit = useCallback(async () => {

    if (!validateCurrentQuestion(questions)) {
      return;
    }

    setActive((prevActive) => {
      if (prevActive + 1 < questions.length) {
        return prevActive + 1;
      }
      return prevActive;
    });

    setIsLoading(true);

    setScoreSheetOverall(
      await postExerciseResult(assetId, parseAnswers(answers))
    );

  }, [questions, validateCurrentQuestion]);

  const handleNextQuestion = useCallback(() => {
    if (!validateCurrentQuestion(questions)) {
      return;
    }

    setActive((prevActive) => {
      if (prevActive + 1 < questions.length) {
        return prevActive + 1;
      }
      return prevActive;
    });
  }, [questions, validateCurrentQuestion]);

  const handlePreviousQuestion = useCallback(() => {
    if (active - 1 >= 0) {
      setActive(active - 1);
    }
  }, [active, questions]);

  const handleQuizReset = () => {
    handleClearAnswers();
    setActive(0);
    setProgress(0);
    setScoreSheetOverall(null);
  };

  useEffect(() => {
    if (getDPTAssetId() && getDPTAssetId().toString().length > 0)
      loadExerciseQuestions(getDPTAssetId()).then((result) => {
        setQuestions(result);

        prepareAnswersObject(result);

        setActive(0);
      });
  }, []);

  useEffect(() => {
    if (questions && questions.length > 0) {
      let currentProgress = 0;

      currentProgress = ((active + 1) / questions.length) * 100;

      setProgress(currentProgress);
    }
  }, [active, questions]);

  useEffect(() => {
    changeLanguage(getCurrentLanguageKey()).then(() => {});

    if (exerciseId && exerciseId.toString().length > 0) {
      setAssetId(exerciseId);
    } else {
      setAssetId(getDPTAssetId());
    }
  }, [changeLanguage]);

  useEffect(() => {
    const fetchData = async () => {
      Liferay.Util.fetch(`/o/c/quizes/${getDPTAssetId()}`)
        .then((response) => response.json())
        .then((response) => {
          setIsKnoledgeCheck(response.isKnowledgeCheck);
          setModuleInfo({passingScore: response.passingScore});
        });
    };
    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (scoreSheetOverall) {
      const titleDuration = document.querySelector(".title-duration");
      if (titleDuration) {
        titleDuration.style.display = "none";
      }
    }

    return () => {
      const titleDuration = document.querySelector(".title-duration");
      if (titleDuration) {
        titleDuration.style.display = "";
      }
    };
  }, [scoreSheetOverall]);

  useEffect(() => {
    Liferay.once("lessonNavigationUpdated", (event) => {
      setNextLesson(getNextLesson());
      setPrevLesson(getPreviousLesson());
    });
  }, []);

  const renderQuizNavigation = () => {
    if (!isSignedIn()) {
      return (
        <div className="button-bottom-home button-bottom-home-unassigned">
          <div>
            <a href={getSignInPath()}>{t("sign-in")}</a>
            {" "} {t("quiz-sign-in-text")}
          </div>
          <button onClick={() => setFirstQuestion(true)}>{t("start-test")}</button>
        </div>
      )
    }
  
    return (
      <div className="button-bottom-home">
        <button onClick={isEnrolled ? () => setFirstQuestion(true) : handleRegisterUser}>{t("start-test")}</button>
      </div>
    );
  };

  return (
    <div>
      {isLoading && <ClayLoadingIndicator displayType="secondary" size="sm" />}

      {scoreSheetOverall && (
        <>
          <QuizResultSheet
            handleReset={handleQuizReset}
            badgePageUrl={badgePageUrl}
            educationPageUrl={educationPageUrl}
            scoreSheetOverall={scoreSheetOverall}
            isKnowledgeCheck={isKnoledgeCheck}
            questions={questions}
          />

          {isKnoledgeCheck && (
            <>
              <div className="bottom-nav-ck">
                  <div>
                      {nextLesson && nextLesson.id && (
                          <a href={`${linkPrefix && linkPrefix.length > 0 ? linkPrefix: '/l/' }${nextLesson.id}`}>
                              <div className="course-nav-bottom__banner d-flex">
                                <div className="banner-options d-flex">
                                  <div className="banner-next-container mr-1">
                                    <ClayIcon symbol="check-circle" className="mr-2"/>
                                      {t('mark-as-complete-and-continue')}
                                    </div>

                                  <div className="banner-title">{nextLesson.title}</div>
                                </div>

                                <div className="banner-icon">
                                  <ClayIcon symbol="order-arrow-right"></ClayIcon>
                                </div>
                              </div>
                          </a>
                      )}
                  </div>

                  {prevLesson && (
                    <>
                      <div className="course-nav-bottom__menu d-flex previous-lesson">
                          <div className="menu-previous-lesson d-flex">
                              <a
                              href={`${
                                  linkPrefix && linkPrefix.length > 0
                                  ? linkPrefix
                                  : "/l/"
                              }${prevLesson.id}`}
                              >
                                  <div className="previous-lesson-icon">
                                      <ClayIcon symbol="order-arrow-left"></ClayIcon>
                                  </div>
                              </a>

                              <div className="previous-lesson-title">
                                  {prevLesson.title}
                              </div>
                              {!isSignedIn() && (
                                  <div className="sign-in-text">
                                      <a href={getSignInPath()}>{t("sign-in")}</a>&nbsp;
                                      {t("to-save-your-progress")}
                                  </div>
                              )}
                          </div>
                      </div>
                    </>
                  )}
              </div>
            </>
          )}
        </>
      )}

      {!scoreSheetOverall && !firstQuestion && questions && (
        <>
          <div className={"progressbar-container"}>
            <div className={"progressbar-tag"}>
              {`${t("progress-lable")} ${active} ${t("of")} ${
                questions.length
              }`}
            </div>
            <ClayProgressBar />
          </div>
          <div className="home-quiz">
            <div className="text-home-quiz">
              <p>
                {!isKnoledgeCheck  
                  ? `${t("quiz-home-message", {
                      passingScore: `${moduleInfo?.passingScore}`,
                      totalQuestions: `${questions.length}`,
                    })}`
                  
                  : `${t("quiz-home-message-ck")}`
                }
              </p>
            </div>
              {renderQuizNavigation()}
          </div>
        </>
      )}

      {!scoreSheetOverall && questions && firstQuestion && (
        <>
          <div className={"progressbar-container"}>
            <div className={"progressbar-tag"}>
              {`${t("progress-lable")} ${active + 1} ${t("of")} ${
                questions.length
              }`}
            </div>
            <ClayProgressBar value={progress} />
          </div>

          {showError && (
            <div className={"my-4"}>
              <ClayAlert displayType="warning" title="Warning" role={null}>
                {t("missing-answer")}
              </ClayAlert>
            </div>
          )}

          <ClayTabs active={active}>
            <ClayTabs.Content activeIndex={active} className={"w-100"} fade>
              {questions &&
                parseAnswers(answers) &&
                questions.map((question, index) => (
                  <ClayTabs.TabPane aria-labelledby="tab-1" key={index}>
                    <div className="question">
                      <div className="question-header">
                        <span className="component-title">
                          {t("question")} {index + 1}
                        </span>
                      </div>
                      <div
                        style={{ minHeight: height }}
                        className="question-body"
                      >
                        <div className="mb-4">
                          <Text>{question.title}</Text>
                        </div>
                        <div className="mb-3">
                          {question.answers &&
                            question.type === "selectSingleChoice" && (
                            <ClayRadioGroup
                                key={question.id}
                                value={parseAnswers(answers)[question.id]}
                                onChange={(value) => {
                                  setAnswers((prevAnswers) => {
                                    let newAnswers = {
                                      ...JSON.parse(prevAnswers),
                                    };
                                    newAnswers[question.id] = value;
                                    
                                    return JSON.stringify(newAnswers);
                                  });
                              }}
                            >
                              {question.answers.map((answer, index) => (
                                <ClayRadio
                                  key={answer.id}
                                  label={answer.title}
                                  value={answer.id}
                                />
                              ))}
                            </ClayRadioGroup>
                          )}

                        {question.answers &&
                          question.type === "selectMultipleChoice" &&
                          question.answers.map((answer, index) => (
                          <div className="mb-3">
                            <ClayCheckbox
                              key={answer.id}
                              label={answer.title}
                              onChange={(value) => {
                                setAnswers((prevAnswers) => {
                                  let newAnswers = {
                                    ...JSON.parse(prevAnswers),
                                  };
                                  newAnswers[question.id][answer.id] =
                                    value.target.checked;
                                  return JSON.stringify(newAnswers);
                                });
                              }}
                              checked={
                                parseAnswers(answers)[question.id][
                                  answer.id
                                ]
                              }
                            />
                          </div>
                        ))}

                        {question.type === "freeText" && (
                          <div className="mb-3">
                            <ClayForm.Group>
                              <label htmlFor={`text_${question.id}`}>
                                {t("your-answer")}
                              </label>
                              <ClayInput
                                key={question.id}
                                id={`text_${question.id}`}
                                component="textarea"
                                placeholder={t("insert-your-answer-here")}
                                type="text"
                                value={parseAnswers(answers)[question.id]}
                                onChange={(event) => {
                                  setAnswers((prevAnswers) => {
                                    let newAnswers = {
                                      ...parseAnswers(prevAnswers),
                                    };
                                    newAnswers[question.id] =
                                      event.target.value;
                                    return JSON.stringify(newAnswers);
                                  });
                                }}
                              />
                            </ClayForm.Group>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="question-footer mb-3 pb-3">
                      <span>
                        {!isSignedIn() && (
                          <>
                            <a href={getSignInPath()}>{t("sign-in")}</a>{" "}
                            {t("quiz-sign-in-text")}
                          </>
                        )}
                      </span>

                      {enableBackButton && (
                        <ClayButton
                          disabled={isLoading}
                          displayType={"secondary"}
                          onClick={handlePreviousQuestion}
                        >
                          {t("back")}
                        </ClayButton>
                      )}

                      {active === questions.length - 1 && (
                        <ClayButton
                          disabled={isLoading}
                          displayType={"primary"}
                          onClick={handleSubmit}
                        >
                          {t("submit")}
                          {isLoading && (
                            <span className="inline-item inline-item-before mx-2">
                              <ClayLoadingIndicator displayType="secondary" size="sm" />
                            </span>
                          )}
                        </ClayButton>
                      )}

                      {active < questions.length - 1 && (
                        <ClayButton
                          displayType={"primary"}
                          onClick={handleNextQuestion}
                        >
                          <span>{t("next")} </span>
                          <ClayIcon symbol={"order-arrow-right"} />
                        </ClayButton>
                      )}
                    </div>

                    {isKnoledgeCheck && isSignedIn() && (
                      <>
                        <div>
                          <div>
                            {nextLesson && nextLesson.id && (
                              <a
                                href={`${
                                  linkPrefix && linkPrefix.length > 0
                                    ? linkPrefix
                                    : "/l/"
                                }${nextLesson.id}`}
                              >
                                <div className="course-nav-bottom__banner d-flex">
                                  <div className="banner-options d-flex">
                                    <div className="banner-next-container mr-1">
                                      <ClayIcon
                                        symbol="check-circle"
                                        className="mr-2"
                                      />
                                      {t("mark-as-complete-and-continue")}
                                    </div>
                                    <div className="banner-title"> {nextLesson.title} </div>
                                  </div>
                                  <div className="banner-icon">
                                    <ClayIcon symbol="order-arrow-right" />
                                  </div>
                                </div>
                              </a>
                            )}
                          </div>

                          {prevLesson && (
                            <>
                              <div className="course-nav-bottom__menu d-flex">
                                <div className="menu-previous-lesson d-flex">
                                  <a
                                    href={`${
                                    linkPrefix && linkPrefix.length > 0
                                      ? linkPrefix
                                      : "/l/"
                                    }${prevLesson.id}`}
                                  >
                                    <div className="previous-lesson-icon">
                                      <ClayIcon symbol="order-arrow-left"></ClayIcon>
                                    </div>
                                  </a>
                                  <div className="previous-lesson-title">
                                    {prevLesson.title}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </ClayTabs.TabPane>
              ))}
            </ClayTabs.Content>
          </ClayTabs>
        </>
      )}
    </div>
  );
});

export default ExerciseView;
