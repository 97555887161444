/* global Liferay */

export const convertMinutesToDuration = (minutes, format = "hours") => {
  const totalSeconds = Math.floor(minutes * 60);
  const hours =
    format === "hours" ? (minutes / 60).toFixed(1) : Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  const seconds = totalSeconds % 60;
  const fractionalHours = `${String(Math.floor(minutes / 60)).padStart(
    2,
    "0"
  )}:${String(minutes % 60).padStart(2, "0")}`;

  switch (format) {
    case "hours":
      return `${hours}`;
    case "hours:minutes":
      return `${fractionalHours}`;
    case "hours:minutes:seconds":
      return `${String(hours).padStart(2, "0")}:${String(mins).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    default:
      return `${fractionalHours}`;
  }
};

export const deleteLessonNavigationCookies = () => {
  const regex = /^lesson_\d+_(previous|next)$/;

  const cookies = document.cookie.split(";");

  cookies.forEach((cookie) => {
    const cookieName = cookie.split("=")[0].trim();

    if (regex.test(cookieName)) {
      document.cookie =
        cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  });
};

export const fetchLiferayAsJSON = async (url) => {
  const response = await Liferay.Util.fetch(url);

  const json = await response.json();

  return json;
};

export const getAdminPanelUrl = () => {
  let url = Liferay.ThemeDisplay.getLayoutRelativeControlPanelURL();

  if (url.indexOf("?") > -1) {
    return url.split("?")[0];
  } else {
    return Liferay.ThemeDisplay.getLayoutRelativeControlPanelURL();
  }
};

export const getChildByAttribute = (element, attribute, value) => {
  return element.querySelector(`[${attribute}="${value}"]`);
};

export const getCookie = (name) => {
  const nameEquals = `${name}=`;

  const cookiesArray = document.cookie.split(";");

  for (let i = 0; i < cookiesArray.length; i++) {
    let cookie = cookiesArray[i].trim();

    if (cookie.indexOf(nameEquals) === 0) {
      return cookie.substring(nameEquals.length, cookie.length);
    }
  }

  return null;
};

export const getCourseFirstLessonId = async (courseId) => {
  const data = await fetchLiferayAsJSON(
    `/o/c/modules/scopes/${getCurrentSiteId()}?filter=r_module_c_courseId%20eq%20%27${courseId}%27%20and%20position%20eq%200&nestedFields=lesson`
  );

  return data.items[0].lesson[0].id;
};

export const getCourseIdByLessonId = async (assetId, assetType = "lesson") => {
  const response = await Liferay.Util.fetch(
    `/o/c/modules/scopes/${getCurrentSiteId()}?fields=r_module_c_courseId&filter=${assetType}%2Fid%20eq%20%27${assetId}%27`
  );

  const data = await response.json();

  return data.items[0].r_module_c_courseId;
};

export const getCreateAccountPath = () => {
  return `${Liferay.ThemeDisplay.getPathMain()}/signin/register`;
};

export const getCurrentLanguage = () => {
  return Liferay.ThemeDisplay.getLanguageId();
};

export const getCurrentLanguageKey = () => {
  const [languageKey, countryKey] =
    Liferay.ThemeDisplay.getLanguageId().split("_");

  return languageKey;
};

export const getCurrentSiteId = () => {
  return Liferay.ThemeDisplay.getScopeGroupId();
};

export const getCurrentUserId = () => {
  return Liferay.ThemeDisplay.getUserId();
};

export const getDPTAssetId = () => {
  if (window.location.pathname.indexOf("/l/") > -1) {
    let path = window.location.pathname.substring(
      window.location.pathname.indexOf("/l/")
    );

    const regex = /\/l\/(\d+)$/;

    const match = path.match(regex);

    if (match) {
      return match[1];
    }

    return null;
  } else {
    return null;
  }
};

export const getNextLesson = () => {
  const regex = /^lesson_\d+_(next)$/;

  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];

    const cookieName = cookie.split("=")[0].trim();

    if (regex.test(cookieName)) {
      return JSON.parse(cookie.split("=")[1].trim());
    }
  }
  return null;
};

export const getNextLessonId = (currentLessonId) => {
  let lessonId = getCookie(`lesson_${currentLessonId}_next`);

  if (lessonId == null) {
    return null;
  } else {
    return lessonId;
  }
};

export const getPersonaStringList = (personaArray) => {
  return personaArray
    .map((persona) => persona.name)
    .sort()
    .join(", ");
};
export const getPreviousLesson = () => {
  const regex = /^lesson_\d+_(previous)$/;

  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];

    const cookieName = cookie.split("=")[0].trim();

    if (regex.test(cookieName)) {
      return JSON.parse(cookie.split("=")[1].trim());
    }
  }
  return null;
};

export const getPreviousLessonId = (currentLessonId) => {
  let lessonId = getCookie(`lesson_${currentLessonId}_previous`);
  if (lessonId == null) {
    return null;
  } else {
    return lessonId;
  }
};

export const getShortText = (text, characterLimit = 150) => {
  if (text.length > characterLimit) {
    const lastSpaceIndex = text.substring(0, characterLimit).lastIndexOf(" ");
    text = text.substring(0, lastSpaceIndex) + "...";
  }
  return text;
};

export const getSignInPath = () => {
  return `${Liferay.ThemeDisplay.getPathMain()}/login`;
};

export const isSignedIn = () => {
  return Liferay.ThemeDisplay.isSignedIn();
};

export const loadStyle = (styleRef) => {
  return new Promise((resolve, reject) => {
    fetch(styleRef)
      .then((response) => response.text())
      .then((cssText) => {
        resolve(cssText + "\n");
      })
      .catch((error) => console.error("Error fetching CSS:", error));
  });
};

export const NotifyComponent = (componentId, key, value) => {
  const modulesListComponent = document.querySelector(componentId);
  modulesListComponent.setAttribute(key, value);
};

export const setCookie = (name, value, hours) => {
  let expires = "";

  if (hours) {
    const date = new Date();

    date.setTime(date.getTime() + hours * 60 * 60 * 1000);

    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ""}${expires}; path=/`;
};

export const showError = (title, message) => {
  Liferay.Util.openToast({ message, title, type: "danger" });
};

export const showSuccess = (
  title,
  message = "The request has been successfully completed."
) => {
  Liferay.Util.openToast({ message, title, type: "success" });
};
