/* global Liferay */

import { forwardRef, useEffect, useState } from "react";
import ClayIcon from "@clayui/icon";
import {
  getCourseIdByLessonId,
  getNextLesson,
  getPreviousLesson,
  isSignedIn,
} from "../utils/util";
import { useTranslation } from "react-i18next";
import { enrollToCourse } from "../services/course";
import { postProgress } from "../services/progress";

const LessonNavigation = forwardRef((props, ref) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const { linkPrefix } = props;

  const [nextLesson, setNextLesson] = useState(null);
  const [prevLesson, setPrevLesson] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [userEnrollments, setUserEnrollments] = useState(null);

  const currentLessonId = Liferay.ThemeDisplay.getLayoutURL().split("/").pop();

  useEffect(() => {
    const getCourseId = async () => {
      setCourseId(await getCourseIdByLessonId(currentLessonId));
    };

    getCourseId();
  }, [currentLessonId]);

  useEffect(() => {
    const getUserEnrollmentsIds = async () => {
      if (isSignedIn()) {
        const response = await Liferay.Util.fetch(
          `/o/c/enrollments/scopes/${Liferay.ThemeDisplay.getScopeGroupId()}?fields=r_courseEnrollment_c_courseId&filter=r_userenrollments_userId eq '${Liferay.ThemeDisplay.getUserId()}'`
        );

        const data = await response.json();

        if (data.items) {
          setUserEnrollments(
            data.items.map((item) => item.r_courseEnrollment_c_courseId)
          );
        }
      }
    };

    getUserEnrollmentsIds();
  }, []);

  useEffect(() => {
    if (userEnrollments && courseId) {
      setIsEnrolled(userEnrollments?.includes(courseId));
    }
  }, [userEnrollments, courseId]);

  useEffect(() => {
    Liferay.once("lessonNavigationUpdated", (event) => {
      setNextLesson(getNextLesson());
      setPrevLesson(getPreviousLesson());
    });
  }, []);

  const handleRegisterUser = async () => {
    if (!userEnrollments?.includes(courseId)) {
      enrollToCourse(courseId);
    }
  };

  const navigateToLesson = (lessonId) => {
    const url = `${linkPrefix && linkPrefix.length > 0 ? linkPrefix : "/l/"}${
      lessonId
    }`;

    window.location.href = url;
  };

  const handleClickCompleteButton = async () => {
    if (isEnrolled) {
      await postProgress(currentLessonId, "lesson", 100);
    }

    if (isSignedIn() && !isEnrolled) {
      await handleRegisterUser();
      await postProgress(currentLessonId, "lesson", 100);
    }

    navigateToLesson(nextLesson.id);
  };

  return (
    <div>
      {nextLesson && nextLesson.id && (
        <div onClick={handleClickCompleteButton}>
          <div className="course-nav-bottom__banner d-flex">
            <div className="banner-options d-flex">
              <div className="banner-next-container mr-1">
                <ClayIcon symbol="check-circle" className="mr-2" />
                {t("mark-as-complete-and-continue")}
              </div>

              <div className="banner-title">{nextLesson.title}</div>
            </div>

            <div className="banner-icon">
              <ClayIcon symbol="order-arrow-right"></ClayIcon>
            </div>
          </div>
        </div>
      )}

      {prevLesson && (
        <div className="course-nav-bottom__menu d-flex">
          <div className="menu-previous-lesson d-flex" onClick={()=> {navigateToLesson(prevLesson.id)}}>
              <div className="previous-lesson-icon">
                <ClayIcon symbol="order-arrow-left"></ClayIcon>
              </div>
            <div className="previous-lesson-title">{prevLesson.title}</div>
          </div>
        </div>
      )}
    </div>
  );
});

export default LessonNavigation;
