/* global Liferay */
import {forwardRef, useCallback, useEffect, useState} from "react";
import ClayLoadingIndicator from "@clayui/loading-indicator";
import {getNavigationMenu} from "../services/menu";
import ClayPanel from '@clayui/panel';
import ClaySticker from "@clayui/sticker";
import {Text} from '@clayui/core';
import ClayIcon from "@clayui/icon";
import {getProgress} from "../services/progress";
import {deleteLessonNavigationCookies, isSignedIn, setCookie} from "../utils/util";

const NavigationMenu = forwardRef((props, ref) => {

    const {assetId, assetType,moduleTextLabel} = props;

    const [isLoading, setIsLoading] = useState(true);
    const [navigationMenu, setNavigationMenu] = useState(null);
    const [navigationMenuData, setNavigationMenuData] = useState(null);
    const [progress,setProgress] = useState(null);
    const [moduleLabel,setModuleLabel] = useState(null);

    const isModuleCompleted = useCallback((moduleId) => {

        let module = navigationMenuData.find(module => module.id === moduleId);

        for (let index = 0; index < module.children.length; index++) {
            if (!isLessonCompleted(module.children[index].id)) {
                return false
            }
        }

        return true;

    },[progress,navigationMenuData])

    const getLessonIndex = useCallback((assetId, index) => {

        let totalSingleLesson = 0;

        for (let moduleIndex = 0; moduleIndex < navigationMenu.length; moduleIndex++) {
            let module = navigationMenu[moduleIndex];

            if (module.id === assetId) {
                return index - totalSingleLesson + 1;
                break;
            }

            if (module.children.length === 1) {
                totalSingleLesson += 1;
            }
        }

        return -1;


    }, [navigationMenu])

    const getModuleIndex = useCallback((assetId) => {

        let index = 1;

        for (let moduleIndex = 0; moduleIndex < navigationMenu.length; moduleIndex++) {

            let module = navigationMenu[moduleIndex];

            if (module.id === assetId) {
                return index;
            }

            if (module.children && module.children.length > 1) {
                index++;
            }

        }


    }, [navigationMenu])


    const prepareMenu = (navigationMenu) => {

        if (navigationMenu && navigationMenu.length > 0) {

            let lastIteration = false;
            let nextLesson = null;
            let previousLesson = null;

            for (let moduleIndex = 0; moduleIndex < navigationMenu.length; moduleIndex++) {

                let module = navigationMenu[moduleIndex];

                module["active"] = false;

                for (let lessonIndex = 0; lessonIndex < module.children.length; lessonIndex++) {

                    let lesson = module.children[lessonIndex];

                    lesson["active"] = false;

                    if(lastIteration){
                        nextLesson = lesson;

                        break;
                    }

                    if (parseInt(lesson.id) === parseInt(assetId)) {
                        module["active"] = true;
                        lesson["active"] = true;
                        lastIteration = true;

                        continue;
                    }

                    previousLesson = lesson;
                }

                if (nextLesson) {
                    break;
                }
            }

            deleteLessonNavigationCookies();

            setCookie(`lesson_${assetId}_next`,JSON.stringify(nextLesson),1);

            setCookie(`lesson_${assetId}_previous`,JSON.stringify(previousLesson),1);

            Liferay.fire("lessonNavigationUpdated",{});

            setNavigationMenu(navigationMenu);
        }

    };

    const isLessonCompleted = useCallback((lessonId) => {
        return progress && (lessonId in progress && progress[lessonId].completed);
    },[progress])

    useEffect(()=>{

        prepareMenu(navigationMenuData)

    },[navigationMenuData,progress])

    useEffect(() => {

        setIsLoading(true);

        setModuleLabel(moduleTextLabel && moduleTextLabel.length > 0 ? moduleTextLabel : "Module");

        getNavigationMenu(assetId, assetType).then(result => {

            let modules = result.items[0].module

            modules = modules.sort((module1, module2) => module1.position - module2.position);

            modules.forEach(
                (module) => {
                    module["children"] = module.lesson
                    
                    module.quiz.forEach(
                        (item) => {
                            module.children.push(item)
                        })

                    module.children.sort((children1, children2) => children1.position - children2.position)
                }
            )

            setNavigationMenuData(modules);

            setIsLoading(false);

        })

        if (isSignedIn()) {

            getProgress().then(result => {

                setProgress(result);

            })

        } else {

            setProgress({});

        }

    }, []);

    return (<>
        {isLoading && (
            <span style={{display: 'inline-block'}}>
                <ClayLoadingIndicator displayType="secondary" size="sm"/>
            </span>

        )}
        {!isLoading && navigationMenu && progress &&(
            navigationMenu.map((navigationMenuItem, index) => {
                return (<>
                    {navigationMenuItem.lesson.length > 1 && (
                        <ClayPanel
                            collapsable
                            displayType="secondary"
                            className="border-0 mb-0"
                            collapseHeaderClassNames = {`${navigationMenuItem.active?"active":""}`}
                            displayTitle={
                                <div className={`d-flex`}>
                                    <ClaySticker shape="circle" displayType={"unstyled"} size="md">
                                        {isModuleCompleted(navigationMenuItem.id) ?
                                            <ClayIcon symbol="check"/> : getLessonIndex(navigationMenuItem.id, index)}
                                    </ClaySticker>
                                    <span title={`${moduleLabel} ${getModuleIndex(navigationMenuItem.id)}: ${navigationMenuItem.title}`} className="module-title my-auto px-2">
                                       <Text size={4} weight={"semi-bold"}>
                                           {`${moduleLabel} ${getModuleIndex(navigationMenuItem.id)}: ${navigationMenuItem.title}`}
                                       </Text>
                                   </span>
                                </div>
                            }
                            showCollapseIcon={true}
                            key={navigationMenuItem.id}
                            defaultExpanded={navigationMenuItem.active}
                        >
                            <ClayPanel.Body className="p-0">
                                {navigationMenuItem.lesson && navigationMenuItem.lesson.map((item, index) => {
                                    return (
                                        <div className={`d-flex px-3 py-3 lesson-link ${isLessonCompleted(item.id)?"completed":"not-completed"} ${item.active?"active":""}`}>
                                            <ClaySticker shape="circle" displayType="primary" size="md">
                                                {isLessonCompleted(item.id) ? <ClayIcon symbol="check"/> : ""}
                                            </ClaySticker>
                                            <span className="module-title my-auto px-2">
                                       <Text size={4} weight={"semi-bold"}>
                                           <a   style={{textDecoration: "none"}}
                                              href={`/l/${item.id}`}>{item.title}</a>
                                       </Text>
                                   </span>
                                        </div>
                                    )
                                })}
                            </ClayPanel.Body>
                        </ClayPanel>
                    )}

                    {navigationMenuItem.lesson.length === 1 && (
                        <div className="d-flex px-3 py-3">
                            {isLessonCompleted(navigationMenuItem.lesson[0].id) && (
                                <ClaySticker shape="circle" displayType="primary" size="md">
                                    <ClayIcon symbol="check"/>
                                </ClaySticker>
                            )}
                            <span className="module-title my-auto px-2">
                                       <Text size={4} weight={"semi-bold"}>
                                           <a className="lesson-link" style={{textDecoration: "none"}}
                                              href={`/l/${navigationMenuItem.lesson[0].id}`}>{navigationMenuItem.lesson[0].title}</a>
                                       </Text>
                                   </span>
                        </div>
                    )}

                </>)
            })
        )}
    </>)

});

export default NavigationMenu;
